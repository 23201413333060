import React, { Component, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import swal from 'sweetalert';

import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import ModalValidationPending from './ModalValidationPending'

import IconLeft from '../../assets/icons/annonces/Arrow_return.svg'

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { Divider } from '@material-ui/core'

import { sendEnquiry, fetchTransactionLineItems, setInitialValues } from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionWeatherMaybe from './SectionWeatherMaybe';
import SectionCapacity from './SectionCapacity';
import css from './ListingPage.module.css';
import { LaptopWindows } from '@material-ui/icons';


const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;


const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
}

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      event_type: null,
      nb_adult: 1,
      nb_child: 0,
      nb_unit: 1,
      time_arriving: 0,
      date: null,
      requiredBeforeMessage: false,
      showModalInfos: this.props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };


    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  //   componentDidMount () {
  //     const script = document.createElement("script");

  //     script.src = "https://apps.elfsight.com/p/platform.js";
  //     script.async = true;

  //     document.body.appendChild(script);

  // }


  componentDidMount(prevProps, prevState) {

    this.handleChangeForWeatherComponent(null);
    window.addEventListener('scroll', this.handleChangeForWeatherComponent);
    window.addEventListener('resize', this.handleChangeForWeatherComponent);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleChangeForWeatherComponent);
    window.removeEventListener('resize', this.handleChangeForWeatherComponent);
  }

  handleChangeForWeatherComponent(event) {
    const weatherComponentDesktop = document.getElementById('weatherComponentDesktop');


    const sectionMapObject = document.getElementById('sectionMap');
    const weatherDividerDesktop = document.getElementById('weatherDividerDesktop');
    const weatherComponentMobile = document.getElementById('weatherComponentMobileLarge');

    const sectionEndBookingPanelObject = document.getElementById('endBookingPanel');

    if (sectionMapObject == null || sectionEndBookingPanelObject == null || sectionEndBookingPanelObject == null || weatherComponentMobile == null) {
      return;

    }
    if (window.outerWidth < 768) {
      if (window.outerWidth < 450) {
        document.getElementById('weatherComponentDesktop').style.display = 'none';
        document.getElementById('weatherComponentMobileLarge').style.display = 'none';
        document.getElementById('weatherComponentMobileSmall').style.display = 'block';
      }
      else {
        document.getElementById('weatherComponentDesktop').style.display = 'none';
        document.getElementById('weatherComponentMobileLarge').style.display = 'block';
        document.getElementById('weatherComponentMobileSmall').style.display = 'none';

      }


    }
    else {
      document.getElementById('weatherComponentDesktop').style.display = 'block';
      document.getElementById('weatherComponentMobileLarge').style.display = 'none';
      document.getElementById('weatherComponentMobileSmall').style.display = 'none';

      const offsetTopForMeteo = sectionMapObject.offsetTop - sectionEndBookingPanelObject.offsetTop > 0 ? sectionMapObject.offsetTop - sectionEndBookingPanelObject.offsetTop - 1 : 0;
      weatherDividerDesktop.style.marginTop = "" + offsetTopForMeteo + "px";
    }

  }


  /**
   * Protected data for message enquiry
   *  
   */
  handleProtectedData = (v) => {

    if (v.event_type) {
      this.setState({ event_type: v.event_type })
    }
    if (v.nb_adult) {
      this.setState({ nb_adult: v.nb_adult })
    }
    if (v.nb_child) {
      this.setState({ nb_child: v.nb_child })
    }
    if (v.nb_unit) {
      this.setState({ nb_unit: v.nb_unit })
    }
    if (v.time_arriving) {
      this.setState({ time_arriving: v.time_arriving })
    }
    if (v.bookingDates && v.bookingDates.date) {
      this.setState({ date: v.bookingDates.date.toString() })
    }
  }

  handleSubmit(values) {

    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getListing } = this.props;
    const { nb_adult, nb_child, event_type, date, nb_unit, time_arriving } = this.state
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const listing = ensureListing(getListing(listingId))
    const category = listing.attributes.publicData.category
    const { message } = values;
    const protectedData = { nb_adult, nb_child, event_type, date, nb_unit, time_arriving };

    if (category !== 'garden_share') {
      if (category === 'tinyhouse') {
        if (!nb_adult || !date || !nb_unit || !message) {
          this.setState({ enquiryModalOpen: false });
          swal('Vous devez renseigner les champs de réservation avant de pouvoir envoyer un message.');
          return;
        }
      }
      else {
        if (!nb_adult || !event_type || !date || !nb_unit || !message) {
          this.setState({ enquiryModalOpen: false });
          swal('Vous devez renseigner les champs de réservation avant de pouvoir envoyer un message.');
          return;
        }
      }
    }

    onSendEnquiry(listingId, message.trim(), protectedData, category)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = publicData.category === "garden_rent"
  ? intl.formatMessage({ id: 'ManageListingCard.perDay' })
  : publicData.category === "classifields"
    ? intl.formatMessage({ id: 'ManageListingCard.perHour' })
    : publicData.category === "tinyhouse"
      ? intl.formatMessage({ id: 'ManageListingCard.perNight' })
      : null;
    const topbar = <TopbarContainer showModalReminder={false} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });





      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar >{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>

        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);



    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter('amenities', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const capacityOptions = findOptionsForSelectFilter('capacity', filterConfig);
    const category = publicData && publicData.category

    if (typeof window === 'undefined') return null

    const weatherHeightDesktop = 320;
    const weatherWidthDesktop = 250;

    const weatherHeightMobileLarge = 150;
    const weatherWidthMobileLarge = 400;

    const weatherHeightMobileSmall = 320;
    const weatherWidthMobileSmall = 200;



    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >

        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <SectionImages
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
                category: publicData && publicData.category
              }}
              imageCarouselOpen={this.state.imageCarouselOpen}
              onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
              handleViewPhotosClick={handleViewPhotosClick}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <div className={css.contentContainer}>
              <div className={css.mainContent}>
                <div className={css.backLinkWrap}>
                  <NamedLink
                    name="SearchPage"
                    className={css.backLink}
                    to={{ search: `?pub_category=${publicData ? publicData.category : "garden_rent"}` }}
                  >
                    <img src={IconLeft} alt="return-arrow" height="15" style={{ marginRight: 5 }} />
                    <FormattedMessage id="ListingPage.backLink" />
                  </NamedLink>
                </div>

                <SectionHeading
                  priceTitle={priceTitle}
                  formattedPrice={formattedPrice}
                  richTitle={richTitle}
                  category={publicData && publicData.category}
                  hostLink={hostLink}
                  currentListing={currentListing}
                  showContactUser={showContactUser}
                  onContactUser={this.onContactUser}
                />

                <SectionDescriptionMaybe description={description} />
                <SectionCapacity publicData={publicData} options={capacityOptions} />
                <SectionFeaturesMaybe options={amenityOptions} publicData={publicData} />
                <Divider />
                <SectionRulesMaybe publicData={publicData} />
                <div id="sectionMap" />
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                />
                <div id="weatherComponentMobileLarge">
                  <Divider />
                  <SectionWeatherMaybe
                    geolocation={geolocation}
                    height={weatherHeightMobileLarge}
                    width={weatherWidthMobileLarge}
                  />
                </div>
                <div id="weatherComponentMobileSmall">
                  <Divider />
                  <SectionWeatherMaybe
                    geolocation={geolocation}
                    height={weatherHeightMobileSmall}
                    width={weatherWidthMobileSmall}
                  />
                </div>
                <Divider />
                <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
              </div>
              <div className={css.bookingWrapper}>
                <div className={css.mobileHide}>
                  <SectionHostMaybe
                    title={title}
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    isPendingApprovalVariant={isPendingApprovalVariant}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>

                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  formattedPrice={formattedPrice}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  showTitle={true}
                  handleProtectedData={this.handleProtectedData}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  onContactUser={this.onContactUser}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  showContactUser={showContactUser}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
                <div className={css.forcastWrapper} >
                  <div className="elfsight-app-3e3cfcb6-4d64-49b5-a262-4776173b4749"></div>
                </div>
                <div id="endBookingPanel" ></div>
                <div>

                  <div id="weatherComponentDesktop">
                    <div id="weatherDividerDesktop" ></div>
                    <Divider />
                    <SectionWeatherMaybe
                      geolocation={geolocation}
                      height={weatherHeightDesktop}
                      width={weatherWidthDesktop}
                    />
                  </div>
                </div>
              </div>

            </div>
            <ModalValidationPending
              open={this.state.showModalInfos}
              handleClose={() => this.setState({ showModalInfos: false })}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, protectedData, category) => dispatch(sendEnquiry(listingId, message, protectedData, category)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData())
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;


