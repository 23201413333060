import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ReactComponent as IconArea } from '../../assets/icons/annonces/area.svg'
import { ReactComponent as IconClock } from '../../assets/icons/annonces/clock.svg'
import { ReactComponent as IconCapacity } from '../../assets/icons/annonces/capacity.svg'
import { ReactComponent as IconPet } from '../../assets/icons/annonces/pet.svg'
import { Grid } from '@material-ui/core'
// CSS
import css from './SectionRulesMaybe.module.css';

const SectionCapacity = props => {
  const { publicData } = props;

  if (!publicData) return null

  if (!publicData.capacity_surface_garden &&
    !publicData.capacity_max_people &&
    !publicData.capacity_surface &&
    !publicData.capacity_surface_tinyhouse
  ) return null


  return <div className={css.sectionCapacity}>
    <Grid container spacing={5}>
      <Grid item xs={6} sm={3}>
        <div className={css.sectionCapacityContent}>
          <IconArea className={css.sectionCapacityIcon} />
          <p><FormattedMessage id="ListingPage.surface" /></p>
          <span>{publicData.capacity_surface_tinyhouse || publicData.capacity_surface_garden} m2</span>
        </div>
      </Grid>
      {
        publicData.category !== "garden_share" &&
        <Grid item xs={6} sm={3}>
          <div className={css.sectionCapacityContent}>
            <IconCapacity className={css.sectionCapacityIcon} />
            <p><FormattedMessage id="ListingPage.capacity" /></p>
            <span>{publicData.capacity_max_people} <FormattedMessage id="ListingPage.people" /></span>
          </div>
        </Grid>
      }
      <Grid item xs={6} sm={3}>
        <div className={css.sectionCapacityContent}>
          <IconClock className={css.sectionCapacityIcon} />
          <p><FormattedMessage id="ListingPage.time" /></p>
          {
            publicData.availability_from !== "not_defined" && publicData.availability_to !== "not_defined" ?
              <span>{publicData.availability_from}h - {publicData.availability_to}h</span>
              :
              <FormattedMessage id="EditListingAvailabilityForm.not_defined" />
          }
        </div>
      </Grid>
      <Grid item xs={6} sm={3}>
        <div className={css.sectionCapacityContent}>
          <IconPet className={css.sectionCapacityIcon} />
          <p><FormattedMessage id="ListingPage.pet" /></p>
          {
            publicData.pet === "yes" ?
              <FormattedMessage id="FieldBoolean.yes" />
              : <FormattedMessage id="FieldBoolean.no" />
          }
        </div>
      </Grid>
    </Grid>





  </div>
};

export default SectionCapacity;